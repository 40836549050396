import log from "cslog";
import { extractData } from "../../data/extractor";
import { bookInfoState, bookPageArrayState } from "../../db/bookConfig";
import { dataPageState } from "../../db/dataDb";
import { elementState } from "../../db/elementDb";

export const encodePageData = async (snapshot, page, book_id = null) => {
	const telementArray = {};
	// const cid = "root";
	const cid = page.pid;
	const data = await snapshot.getPromise(elementState(page.pid));

	await extractData(cid, data.pid, data, snapshot, telementArray);
	return {
		pid: page.pid,
		id: page.id,
		page_no: page.page_no,
		info: page,
		size: page.size,
		html: telementArray,
		content: await snapshot.getPromise(dataPageState(page.pid)),
		book_id: book_id,
	};
};

export function prepareInput(page_inputs, used_fields = {}) {
	log.d(page_inputs, "Preparing page inputs")
	const out = {};
	for (const key in page_inputs) {
		const datablock = page_inputs[key];
		let data = {};
		switch (datablock.block_type) {
			case "qureal_database":
				data = {
					name: datablock.data.name,
					table: datablock.data.table,
					type: datablock.data.type,
					filter: datablock.data.filter,
					fieldString: datablock.data.fieldString,
					used_fields: used_fields?.[key] || []
				}
				break;
			case "qureal_preset":
				data = {
					qureal_schema_id: datablock.data.qureal_schema_id,
					extra: datablock.data.extra,
					default: datablock.data.default || {},
					preset_context: datablock.data.preset_context,
					used_fields: used_fields?.[key] || []
				}
				break;
		}
		out[key] = {
			block_type: datablock.block_type,
			data: {
				...data,
				used_fields: used_fields?.[key] || []
			}
		};
	}
	return out;
}

function findUsedFields(page_html) {
	log.d(page_html, "pPage html")
	const out = {}
	for (const key in page_html) {
		const value = page_html[key];
		const input = value?.input || {};
		log.d(input, "pPage input")
		Object.keys(input).map((in_key) => {
			const in_value = input[in_key];
			if (typeof in_value === "object" && "id" in in_value) {
				const id = in_value?.id;
				const field = in_value?.field;
				out[id] = [
					...(out[id] || []),
					field
				]
			}
		})
	}
	return out
}

export const encodeMagazineData = async (snapshot, config) => {
	const info = await snapshot.getPromise(bookInfoState);

	const pages = [];
	const pageslist = await snapshot.getPromise(bookPageArrayState);
	log.d(pageslist, "BookPageLIST");

	const temp_pages = {};
	const temp_inputs = {};
	await Promise.all(
		pageslist.map(async (page) => {
			// log.d(page, "Next Page");
			const pageData = await encodePageData(snapshot, page, info.id);
			// pages.push(pageData);
			temp_pages[page.pid] = pageData;

			const page_inputs = await snapshot.getPromise(dataPageState(page.pid));

			const used_fields = findUsedFields(pageData.html)
			log.d(used_fields, "pPage used_fields")



			temp_inputs[page.pid] = prepareInput(page_inputs, used_fields);

			log.d(temp_inputs[page.pid], "pPage Input out")


		})
	);

	pageslist.forEach((page) => {
		pages.push(temp_pages[page.pid]);
	});

	const data = {
		page_array: pageslist,
		pages: pages,
		inputs: temp_inputs,
	};

	return data;
};
