import log from "cslog";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQueries, useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { secureAxios } from "../../_helper/auth";
import { currentContextState } from "../../db/bookConfig";
import { encodePresetValues } from "../../_helper/preset";

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const ORG_TABLES = ["qd_product", "qd_product_category", "directus_users"];

function getOrgFilter(datablock, org) {
	if (org?.id) {
		if (ORG_TABLES.includes(datablock.table)) {
			return {
				field: "organization",
				op: "_eq",
				value: {
					type: "fixed",
					value: org.id,
				},
				subfield: "id",
			};
		}
		return null;
	}
	return null;
}
export const createFieldString = (table) => {
	const fields = table.fields;
	let out = "fields=";
	fields.forEach((f) => {
		if (f.interface?.type === "relationM2M") {
			out += `${f.name}.${f.interface?.junction_field}.*,`;
		} else if (f.interface?.type === "relationM2O") {
			out += `${f.name}.*,`;
		} else if (f.interface?.type === "image") {
			out += `${f.name}.*,`;
		} else {
			out += `${f.name},`;
		}
	});
	return out.substring(0, out.length - 1);
};

export const createDisplayConfig = (table) => {
	const out = {};
	// table.fields.forEach((f) => {
	table.fields.sort((a, b) => a.sort - b.sort).forEach((f) => {
		out[f.name] = {
			type: f.interface?.type,
		};
		if (f.interface?.type?.startsWith("relation")) {
			let render_type = null;
			switch (f.interface?.foreign_key_table) {
				case "directus_files":
					render_type = "image";
					break;
				case "directus_users":
					render_type = "user";
					break;
				default:
					break;
			}
			if (render_type) {
				out[f.name] = {
					...out[f.name],
					render_type: render_type,
				};
			}
		}
	});
	return out;
};

const decodeContentData = (data, db) => {
	const display = db.display;
	// log.d(display, "dc Display");
	// log.d(data, "dc Data");
	if (display) {
		const out = {};
		Object.entries(display).forEach(([key, value]) => {
			const val = data[key];
			switch (value?.type) {
				case "image":
					out[key] = val?.id;
					// out[key] = val
					break;
				case "relationM2O":
					if (value.render_type === "user") {
						out[key] = `${val?.first_name} ${val?.last_name}`;
					} else if (value.render_type === "image") {
						out[key] = val?.id;
					}
					break;
				case "relationM2M":
					// const temp = [];
					// val?.forEach((v) => {
					//     const junction_field = Object.keys(v)[0];
					//     temp.push(v[junction_field]);
					// });
					// out[key] = temp;
					out[key] = "Array of M2M";
					break;
				case "datetime":
					// TODO: Parse datetime
					// out[key] = val;
					out[key] = dayjs(val).format("MMMM DD, YYYY [at] h:m A");
					// out[key] = dayjs().to(dayjs(val));
					break;
				default:
					out[key] = val;
			}
		});
		return out;
	}
	return data;
};

const decodeResponse = (data, db) => {
	const display = db.display;
	// log.d(display, "Display");
	// log.d(data, "Decoding Data");
	if (display) {
		if (Array.isArray(data)) {
			// return data;
			const out = [];
			data.forEach((item) => {
				out.push(decodeContentData(item, db));
			});
			// const out = data.map((item) => decodeContentData(item, db));
			// log.d(out, "Array Out");
			return out;
		} else {
			const out = decodeContentData(data, db);
			return out;
		}
	}
	return data;
};

// export const decodeContent = (content) => {
//     const out = {};
//     Object.keys(content).forEach((key) => {
//         const item = content[key];
//         if (item.isSuccess) {
//             out[key] = {
//                 ...item,
//                 data: decodeContentData(item.data),
//             };
//         }
//     });
//     return out;
// };

function decodeValue(value) {
	switch (value.type) {
		case "fixed":
			return value.value;
		case "url_path":
			return value.placeholder;
		default:
			return "";
	}
}

function prepareURL(datablock, dbc, dy_filter = [], inputs = {}) {
	let url = `/items/${datablock.table}`;
	switch (datablock.table) {
		case "directus_users":
			url = "/users";
			break;
		default:
			break;
	}

	log.d(inputs, "Inputs in prepareURL");

	const filter = datablock.filter;
	if (datablock.type === "single") {
		if (filter?.type === "fixed") {
			url += `/${filter.value}?`;
		} else if (filter?.type === "url_path") {
			url += `/${filter.placeholder}?`;
		} else if (filter?.type === "url_param") {
			url += `/${filter.placeholder}?`;
		} else if (filter?.type === "input") {
			// url += `/${inputs[filter?.param]}?`;
			url += `/${inputs[filter?.param] || filter?.placeholder}?`;
		}
	} else {
		url += "?";
		// log.d(filter, "Filter prepareURL");
		// const conds = filter.conditions;
		const conds =
			dy_filter.length > 0
				? [...filter?.conditions, ...dy_filter]
				: filter?.conditions;
		// log.d(conds, "conds in query");
		let filter_arr = [];
		if (conds?.length > 0) {
			let filter_out = null;
			conds.forEach((cond) => {
				let value = decodeValue(cond.value);
				let obj = { [cond.field]: { [cond.op]: value } };
				if (cond.subfield) {
					if (cond.junctionfield) {
						obj = {
							[cond.field]: {
								[cond.junctionfield]: {
									[cond.subfield]: { [cond.op]: value },
								},
							},
						};
					} else {
						obj = {
							[cond.field]: {
								[cond.subfield]: { [cond.op]: value },
							},
						};
					}
				}
				filter_arr.push(obj);
			});

			if (conds.length === 1) {
				filter_out = filter_arr[0];
			} else {
				const matchType = filter.matchType === "any" ? "_or" : "_and";
				filter_out = {
					// [filter.type || "_and"]: filter_arr,
					[matchType || "_and"]: filter_arr,
				};
			}
			// log.d(filter_out, "Filter Output");
			url += `&filter=${JSON.stringify(filter_out)}`;
		}
	}

	//search
	if (dbc.search && dbc.search?.length > 0) {
		url += `&search=${dbc.search}`;
	}

	//pagination
	const per_page = dbc.per_page || 10;
	const page_no = dbc.page_no || 1;
	url += `&limit=${per_page}`;
	url += `&offset=${(page_no - 1) * per_page}`;

	//metadata
	url += "&meta=filter_count";

	if (datablock.fieldString) {
		if (datablock.table === "directus_users") {
		} else {
			url += `&${datablock.fieldString}`;
		}
	}
	// log.d(url, "URL OUT");
	return url;
}

export const useSingleContent = (block, enabled = true, inputs = {}) => {
	const datablock = block.data || block;
	const filter = datablock.filter;
	// const org = useRecoilValue(organizationState);
	const current_context = useRecoilValue(currentContextState)
	const org = current_context.organization;

	const dy_filter = [];
	const org_filter = getOrgFilter(datablock, org);
	if (org_filter) {
		dy_filter.push(org_filter);
	}

	let url = prepareURL(datablock, {}, dy_filter, inputs);

	log.d(url, "single content url");

	return useQuery(
		[
			"items",
			datablock.table,
			datablock.type,
			JSON.stringify(filter),
			datablock.fieldString,
			dy_filter,
		],
		() =>
			secureAxios.get(url).then((res) => {
				let out = res.data;
				log.d(out, "Out pre in query");
				if (datablock.type === "single") {
					if (Array.isArray(out)) {
						out = out[0];
					}
				}
				log.d(out, "Out in Query");
				// return out;
				return decodeResponse(out, datablock);
			}),
		{ enabled: enabled }
	);
};

export const applySequence = (content, seq_data) => {
	const out = {};
	Object.keys(content).forEach((key) => {
		const data = content[key]?.data?.data;
		const seq = seq_data[key];
		if (Array.isArray(data) && seq && seq <= data?.length) {
			out[key] = {
				...content[key],
				data: data[seq - 1],
			};
		} else {
			out[key] = content[key];
		}
	});
	return out;
};

//db -
//dbc-
export const useContent = (
	db,
	dbc,
	filled_data = {},
	inputs = {},
	seq_data = null
) => {
	// const org = useRecoilValue(organizationState);
	const current_context = useRecoilValue(currentContextState);
	const org = current_context.organization;

	// log.d(db, "DB in uc");
	// log.d(dbc, "DBC in uc");

	// return {
	//     msg: "sample data reached",
	// };
	const query = useQueries(
		Object.keys(db).map((key) => {
			const block = db[key];

			const block_type = block?.block_type;
			//Fillable==================
			if (block_type === "fillable") {
				const filled_block_data = filled_data[key] || {};
				const fields = block?.data?.fields || [];
				const out = {};
				fields.forEach((item) => {
					out[item.name] =
						filled_block_data[item.name] || item.default_value;
				});
				return {
					queryKey: [
						JSON.stringify(fields),
						JSON.stringify(filled_block_data),
					],
					queryFn: () => {
						return out;
					},
					keepPreviousData: true,
				};
			} else if (block_type === "qureal_preset") {
				const preset_block_data = filled_data[key] || {};

				// log.d(block, "preset block")

				const old_default_values = {};
				block?.data?.fields?.forEach((item) => {
					old_default_values[item.name] = item.default_value
				})
				// log.d(old_default_values, "old_default_values in query");

				const default_values = block?.data?.default || {};
				// const fields = block?.data?.fields || [];
				const decoded_default_values = encodePresetValues(default_values, "")
				log.d(decoded_default_values, "decoded_default_values in query");
				const out = {
					...preset_block_data,
					...old_default_values,
					...decoded_default_values
				}
				// log.d(out, "out in useContent");
				return {
					queryKey: [
						// JSON.stringify(fields),
						JSON.stringify(preset_block_data),
						JSON.stringify(default_values),
					],
					queryFn: () => {
						return out;
					},
					keepPreviousData: true,
				};
			} else if (block_type === "qureal_database") {
				const item = block.data || block;
				const filter = item.filter;

				const current_dbc = dbc[key] || {};
				log.d(current_dbc, "Current DBC");

				const dy_filter = [];
				if (current_dbc.filter) {
					dy_filter.push(current_dbc.filter);
				}
				// if (filters[key]) {
				//     dy_filter.push(filters[key]);
				// }
				const org_filter = getOrgFilter(item, org);
				if (org_filter) {
					dy_filter.push(org_filter);
				}

				let url = prepareURL(item, current_dbc, dy_filter, inputs);
				// log.d(url);

				return {
					queryKey: [
						item.table,
						item.type,
						JSON.stringify(filter),
						item.fieldString,
						JSON.stringify(current_dbc),
						dy_filter,
						inputs
					],
					queryFn: () =>
						secureAxios.get(url).then((res) => {
							let out = res.data;
							if (item.type === "single") {
								if (Array.isArray(out)) {
									out = out[0];
								}
								return decodeResponse(out, item);
							}
							// return out;
							return {
								meta: res.meta,
								data: decodeResponse(out, item),
							};
						}),
					keepPreviousData: true,
				};
			} else if (block_type === "ai_model") {
				const data = block.data || block;
				return {
					queryKey: ["items", "qdb_ai_model_qdb_ai_pose", data.model, data.pose],
					queryFn: () =>
						secureAxios
							.get(`/items/qdb_ai_model_qdb_ai_pose?filter[qdb_ai_pose_id][_eq]=${data.pose}&filter[qdb_ai_model_id][_eq]=${data.model}&limit=1`)
							.then((res) => {
								let out = res.data?.[0];
								return out;
							}),
					keepPreviousData: true,
				}

			} else if (block_type === "CSV") {
				const item = block.data || block;

				let url = `/items/qdb_data_file/${item.data_file}`;
				log.d(url, "CSV query url");

				return {
					queryKey: ["items", "qdb_data_file", item.data_file],
					queryFn: () =>
						secureAxios.get(url).then((res) => {
							let out = res.data;
							log.d(res, "CSV response");
							const data = res?.data?.data?.rows || [];

							// const seq = seq_data?.[key];
							// if (seq && seq <= data.length) {
							//     return data[seq];
							// }

							return {
								meta: {
									total_count: data.length,
								},
								data: data,
							};
						}),
					keepPreviousData: true,
				};
			}
		})
	);

	const out = {};

	Object.keys(db).map((key, index) => {
		out[key] = query[index];
	});

	return out;
};

export const useAdditionalContent = (db) => {
	let out = {};
	Object.keys(db).forEach((key) => {
		const obj = db[key];
		out[key] = {
			status: "success",
			isSuccess: true,
			data: obj.resolve.value,
		};
	});
	return out;
};
