/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import SlateElement, { SlateElementView } from './SlateElement'
import Leaf from './Leaf'
import log from 'cslog'

const SlateViewer = ({
	id,
	input,
	keepFresh = true,
	padding = 0,
	dydb = {},
	styles = {}
}) => {
	const [key, setKey] = useState(0)
	const editor = useMemo(() => withReact(createEditor()), [])
	const [value, setValue] = useState(input.html)
	const renderElement = useCallback(
		props => <SlateElementView {...props} dydb={dydb} />,
		[dydb]
	)
	const renderLeaf = useCallback(props => <Leaf {...props} />, [])

	useEffect(() => {
		setValue(input.html)
		setKey(n => n + 1)
	}, [input.html])

	log.d(dydb, 'dydb in SlateViewer')

	return (
		<div
			css={css`
        .qslate p,
        .qslate li,
        .qslate blockquote {
		    font-size: 1em;
            line-height: ${input.lineHeight * 100}%;
            letter-spacing: ${input.letterSpacing.value};
		    font-family: inherit;
        }
		.qslate h1, .qslate h2, .qslate h3, .qslate h4, .qslate h5, .qslate h6 { 
	        font-family: ${styles?.fontFamilyHeadings || 'inherit'};
	        margin: 0 0 1em 0;
	        line-height: 1.2;
        }
		.qslate h1{font-size: 2.4em;}
		.qslate h2{font-size: 2em;}
		.qslate h3{font-size: 1.7em;}
		.qslate h4{font-size: 1.4em;}
		.qslate h5{font-size: 1.2em;}
		.qslate h6{font-size: 1em;}
        // .qslate span { border-radius: 3px;}
      `}
		>
			<Slate editor={editor} value={value} key={keepFresh ? key : id}>
				<Editable
					readOnly
					placeholder='Click here to write...'
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					className='qslate'
					style={{
						padding: padding
					}}
				/>
			</Slate>
		</div>
	)
}

export default SlateViewer
