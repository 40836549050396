/** @jsxImportSource @emotion/react */
// eslint-disable-next-line no-unused-vars
import log from "cslog";
import React from "react";
import { decodeStyle, getHTMLContent, parseStyle, SlateViewer } from "../../../junctionAbs";
import ElementWrapper from "./wrappers/ElementWrapper";

function HtmlText({ id, db, gs, dydb, ctx }) {
	const data = db[id];

	const ps = parseStyle(data, gs, data.cstate);

	let input = ps.input;

	let styles_decoded = decodeStyle(ps.style, gs);

	const html = getHTMLContent(dydb, input.html);

	return (
		<ElementWrapper id={id}>
			<div
				style={{
					columnWidth: "auto",
					columnCount: input.columnCount,
					...decodeStyle(ps.fixStyle, gs),
					// ...decodeStyle(ps.style, gs),
					...styles_decoded,
					// "&:hover": decodeStyle(ps.hover, gs),
					textAlign: input.textAlign,
					fontSize: input.fontSize || 16,
				}}
			>
				<SlateViewer
					id={id}
					input={{
						...input,
						html: html
					}}
					styles={styles_decoded}
					dydb={dydb}
				/>
			</div>
		</ElementWrapper>
	);
}

export default HtmlText;
