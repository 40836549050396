import log from "cslog";
import { extractData } from "../../data/extractor";
import { bookInfoState, bookPageArrayState } from "../../db/bookConfig";
import { dataPageState } from "../../db/dataDb";
import { elementState } from "../../db/elementDb";

export const encodePageData = async (snapshot, page, book_id = null) => {
	const telementArray = {};
	// const cid = "root";
	const cid = page.pid;
	const data = await snapshot.getPromise(elementState(page.pid));

	await extractData(cid, data.pid, data, snapshot, telementArray);
	return {
		pid: page.pid,
		id: page.id,
		page_no: page.page_no,
		info: page,
		size: page.size,
		html: telementArray,
		content: await snapshot.getPromise(dataPageState(page.pid)),
		book_id: book_id,
	};
};

export function prepareInput(page_inputs) {
	log.d(page_inputs, "Preparing page inputs")
	const out = {};
	for (const key in page_inputs) {
		const datablock = page_inputs[key];
		let data = {};
		switch (datablock.block_type) {
			case "qureal_database":
				data = {
					name: datablock.data.name,
					table: datablock.data.table,
					type: datablock.data.type,
					filter: datablock.data.filter,
					fieldString: datablock.data.fieldString,
				}
				break;
			case "qureal_preset":
				data = {
					qureal_schema_id: datablock.data.qureal_schema_id,
					extra: datablock.data.extra,
					default: datablock.data.default || {},
					preset_context: datablock.data.preset_context
				}
				break;
		}
		out[key] = {
			block_type: datablock.block_type,
			data: data
		};
	}
	return out;
}

export const encodeMagazineData = async (snapshot, config) => {
	const info = await snapshot.getPromise(bookInfoState);

	const pages = [];
	const pageslist = await snapshot.getPromise(bookPageArrayState);
	log.d(pageslist, "BookPageLIST");

	const temp_pages = {};
	const temp_inputs = {};
	await Promise.all(
		pageslist.map(async (page) => {
			// log.d(page, "Next Page");
			const pageData = await encodePageData(snapshot, page, info.id);
			// log.d(page, "pPage");
			// pages.push(pageData);
			temp_pages[page.pid] = pageData;

			const page_inputs = await snapshot.getPromise(dataPageState(page.pid));
			temp_inputs[page.pid] = prepareInput(page_inputs);
		})
	);

	pageslist.forEach((page) => {
		pages.push(temp_pages[page.pid]);
	});

	const data = {
		page_array: pageslist,
		pages: pages,
		inputs: temp_inputs,
	};

	return data;
};
