import {
	selectOption,
	textOption,
	longTextOption,
	booleanOption,
	listOption,
	dynamicSelectOption,
	optionRowIcon,
	optionRowNumber,
	imgSrcOption,
	fileOption,
	iconOption,
	rangeOption,
	ytSourceOption,
	colorOption,
	rangeWithDefault,
	textDebounceOption,
	fourSide,
	urlOption,
	codeOption,
	numberOption,
} from "./SimpleOptions";
import {
	textEditOptions,
	imgSizeOption,
	headerTagOption,
	iconSizeOption,
	frameShapeOption,
	shapeClipOption,
	actionOption,
	htmlOption,
	widthsOption,
	buttonTypeOptions,
	colorPaletteOption,
	fontSetOption,
	classesOption,
	sectionStructureOption,
	gcolorOption,
	stickyOption,
	sectionWidthOption,
	pageOption,
	formOption,
	roleOption,
	recordIdPicker,
	setBackground,
	fillOption,
	autofitTextOption,
} from "./ComplexOptions";
import {
	iconListDataOption,
	imgArrayOptions,
	navMenuDataOptions,
	socialListDataOptions,
	TabsDataOption,
	TableDataOption,
	GraphDataOption,
	breadcrumListDataOptions,
	SocialIconDataOption,
} from "./DataOptions";
import startCase from "lodash/startCase";
import log from "cslog";
import { OPTIONS } from "../data/extras";
import {
	dynamicItemOption,
	dynamicListOption,
	dynamicListColumnsOption,
	dyanmicColumnOption,
	dynamicFilterConfigOption,
} from "./DynamicOptions";

export function contentOptions(
	key,
	itype,
	input,
	value,
	setValue,
	data,
	setData,
	gs,
	pages,
	trigger,
	rest
) {
	switch (itype) {
		case "headingTag":
			return headerTagOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "textEdit":
			return textEditOptions();
		case "textEditBasic":
			return textEditOptions(true);
		case "shorttext":
			return textOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "classes":
			return classesOption(key, value, setValue);
		case "sectionLayout":
			return sectionStructureOption(key, value, (newVal) =>
				setValue(key, newVal)
			);
		case "text":
			return longTextOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "textDebounce":
			return textDebounceOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "html":
			return htmlOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				trigger
			);
		case "buttonType":
			return buttonTypeOptions(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal)
				// setValue
			);
		// case "sectionLayout":
		//     return widthsOption(key, value, (newVal) => setValue(key, newVal));
		case "boolean":
			return booleanOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "selectOption":
			return selectOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				OPTIONS[key]
			);
		case "dynamicColumn":
			return dyanmicColumnOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				rest
			);
		case "filterConfigs":
			return dynamicFilterConfigOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				rest
			);
		case "list":
			return listOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "coloronly":
			return colorOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "color":
			return gcolorOption(
				startCase(key),
				value,
				(newVal) => {
					setValue(key, newVal);
				},
				gs
			);
		case "iconListData":
			return iconListDataOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "navListData":
			return navMenuDataOptions(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "socialListData":
			// return socialListDataOptions(startCase(key), value, (newVal) =>
			// 	setValue(key, newVal)
			// );  
			return <SocialIconDataOption
				label={startCase(key)}
				input={input}
				setOneInput={setValue}
				data={data}
				setData={setData}
				gs={gs}
			/>
		case "breadcrumbListData":
			return breadcrumListDataOptions(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "listStyle":
			return dynamicSelectOption(
				"List Type",
				"List Style",
				value.tag,
				value.style,
				(newVal) => {
					log.d(newVal, "NewVAL");
					setValue(key, {
						tag: newVal,
						style: newVal === "ul" ? "disc" : "1",
					});
				},
				(newVal) => setValue(key, { ...value, style: newVal }),
				[
					["Unordered", "ul"],
					["Ordered", "ol"],
				],
				{
					ul: [
						["None", "none"],
						["Disc", "disc"],
						["Square", "square"],
						["Circle", "circle"],
					],
					ol: [
						["Numeric", "1"],
						["Alphabatic", "A"],
						["alphabatic", "lower-alpha"],
						["Roman", "I"],
						["roman", "lower-roman"],
					],
				}
			);
		case "filledStars":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				0,
				input.totalStars || 10,
				0.1
			);
		case "optionRow":
			return optionRowNumber(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				OPTIONS[key]
			);
		case "optionRowIcon":
			return optionRowIcon(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				OPTIONS[key]
			);
		case "imgSrc":
			return imgSrcOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				trigger,
				"img",
				setData
			);
		case "svgSrc":
			return imgSrcOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				trigger,
				"svg",
				setData,
			);
		case "shapeSrc":
			return imgSrcOption(
				startCase(key),
				value || {},
				(newVal) => setValue(key, newVal),
				trigger,
				"shape",
				setData,
			);
		case "autofitText":
			return autofitTextOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "fill":
			return fillOption(key, value, setValue, gs, input);
		case "imgArray":
			return imgArrayOptions(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "fileAudio":
			return fileOption("audio", startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "filePDF":
			return fileOption("pdf", startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "file3d":
			return fileOption("file3d", startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "ytSource":
			return ytSourceOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "iconSrc":
			return iconOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "imgSize":
			return imgSizeOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "maxLines":
			return rangeWithDefault(
				startCase(key),
				"Max number of lines",
				value,
				(newVal) => setValue(key, newVal),
				1,
				20,
				1,
				"unset",
				4,
				(val) => val
			);
		case "imageHeight":
			return rangeWithDefault(
				startCase(key),
				"Custom Height (% of width)",
				value,
				(newVal) => setValue(key, newVal),
				1,
				200,
				1,
				"auto",
				100,
				// (val) => `${val}em`
				(val) => `${val}%`
			);
		case "iconSize":
			return iconSizeOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "lineHeight":
			return rangeOption(
				startCase(key),
				value || 0.2,
				(newVal) => setValue(key, newVal),
				// 0.2,
				0,
				3,
				0.1
			);
		case "letterSpacing":
			return rangeWithDefault(
				startCase(key),
				"Custom Letter Spacing",
				value,
				(newVal) => setValue(key, newVal),
				-1.0,
				2,
				0.02,
				"normal",
				0.06,
				(val) => `${val}em`
			);
		case "iconGap":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				0,
				3,
				0.1
			);
		case "opacity":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				0,
				1,
				0.1
			);
		case "zIndex":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				-5,
				15,
				1
			);
		case "height":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				1,
				300,
				1
			);
		case "frameShape":
			return frameShapeOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "shapeClip":
			return shapeClipOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "standardRange":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				1,
				100,
				1
			);
		case "standardNumber":
			return numberOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				1,
				100
			);
		case "fullRange":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				0,
				100,
				1
			);
		case "rangeToTen":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				1,
				10,
				1
			);
		case "rangeToTwenty":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				1,
				20,
				1
			);
		case "url":
			return urlOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "pageMargin":
			return rangeOption(
				`${startCase(key)} (in vw)`,
				value.params,
				(newVal) =>
					setValue(key, {
						type: "vw",
						params: newVal,
						value: `${newVal}vw`,
					}),
				0,
				100,
				1
			);
		case "action":
			return actionOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				pages
			);
		case "form":
			return formOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "recordIdPicker":
			return recordIdPicker(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "page":
			return pageOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "role":
			return roleOption(startCase(key), value, (newVal) =>
				setValue(key, newVal)
			);
		case "colorPalette":
			return colorPaletteOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "sticky":
			return stickyOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "fontSet":
			return fontSetOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "pageMaxWidth":
			return rangeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				100,
				2000,
				10
			);
		case "sectionWidth":
			return sectionWidthOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "dynamicList":
			return dynamicListOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "dynamicItem":
			return dynamicItemOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "dynamicListColumns":
			return dynamicListColumnsOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				gs
			);
		case "tabsData":
			return (
				<TabsDataOption
					label={startCase(key)}
					input={input}
					setOneInput={setValue}
					data={data}
					setData={setData}
					gs={gs}
				/>
			);
		case "tableData":
			return (
				<TableDataOption
					label={startCase(key)}
					input={input}
					setOneInput={setValue}
					data={data}
					setData={setData}
					gs={gs}
				/>
			);
		case "graphData":
			return (
				<GraphDataOption
					label={startCase(key)}
					input={input}
					setOneInput={setValue}
					data={data}
					setData={setData}
					gs={gs}
				/>
			);
		case "graphDataSingle":
			return (
				<GraphDataOption
					label={startCase(key)}
					input={input}
					setOneInput={setValue}
					data={data}
					setData={setData}
					gs={gs}
					single={true}
				/>
			);
		case "padding":
			return fourSide(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				({ top, right, bottom, left }) => {
					return `${top}px ${right}px ${bottom}px ${left}px`;
				}
			);
		case "code_json":
			return codeOption(
				startCase(key),
				value,
				(newVal) => setValue(key, newVal),
				"json"
			);
		default:
			return null;
	}
}
