import log from "cslog";
import { getImageURL, parseImgURL } from "../_helper/image";
import { parseHtmlToSlate } from "./parser_slate";

const isString = (value) => {
	return typeof value === "string" || value instanceof String;
};

function getTextContent(data, content) {
	return (
		(content.before || "") +
		(data[content.field]?.toString() || "-") +
		(content.after || "")
	);
}


function getHTML(data, content) {
	// return data[content?.field];
	// return parseHtmlToSlate(data[content?.field]);
	const val = data[content?.field];
	if (val) {
		return parseHtmlToSlate(data[content?.field]);
	}
	return parseHtmlToSlate("");
}

export const getTableContent = (db, tableData) => {
	if (tableData?.type !== "dynamic") {
		return tableData;
	}
	const data = db[tableData?.source];
	if (data) {
		if (data.isSuccess) {
			// success
			return data?.data?.data;
			// return "DATA";
		} else if (data.isLoading) {
			return "Loading...";
		} else {
			return "Something went wrong";
		}
	} else {
		return "Table data source is not configured";
	}
};

export const getRecordId = (db, record_config = {}) => {
	if (!record_config.enable) {
		return null;
	}
	switch (record_config.type) {
		case "db":
			const params = record_config.params;
			const itemDb = db[params?.id];
			if (itemDb) {
				if (itemDb.isSuccess) {
					const data = itemDb.data;
					if (Array.isArray(data)) {
						return data[0][params.field];
					}
					return data[params.field];
				} else if (params?.id === "parent") {
					return itemDb[params.field];
				}
			}
			return null;
		default:
			return null;
	}
};


export const getContentObject = (db, data_source) => {
	// return data_source;
	const itemDb = db[data_source];
	if (itemDb) {
		if (itemDb.isSuccess) {
			const data = itemDb.data;
			if (Array.isArray(data)) {
				return data[0];
			}
			return data;
		} else if (itemDb.isLoading) {
			return "Loading....";
		} else if (data_source === "parent") {
			return itemDb;
		}
		return "Error occured";
	}
	return "Not available";
};

export const getHTMLContent = (db, content) => {
	if (Array.isArray(content)) {
		return content;
	}

	log.d(content, "Content in getHTMLContent")
	log.d(db, "db in getHTMLContent")

	// return "Dynamic Data";
	const itemDb = db[content?.id];
	if (itemDb) {
		if (itemDb.isSuccess) {
			const data = itemDb.data;
			if (Array.isArray(data)) {
				// return data[0][content.field]?.toString() || "...";
				return getHTML(data[0], content);
				// return data;
			}
			// return data[content.field]?.toString() || "---";
			return getHTML(data, content);
		} else if (itemDb.isLoading) {
			return parseHtmlToSlate("Loading....");
		} else if (content?.id === "parent") {
			// return itemDb[content.field]?.toString() || ",,";
			return getHTML(itemDb, content);
		}
		return parseHtmlToSlate("Error occured");
	}
	return parseHtmlToSlate("Not available");
};

export const getContent = (db, content) => {
	if (isString(content)) {
		return content;
	}

	log.d(content, "Content in getContent")
	log.d(db, "db in getContent")

	// return "Dynamic Data";
	const itemDb = db[content?.id];
	if (itemDb) {
		if (itemDb.isSuccess) {
			const data = itemDb.data;
			if (Array.isArray(data)) {
				// return data[0][content.field]?.toString() || "...";
				return getTextContent(data[0], content);
			}
			// return data[content.field]?.toString() || "---";
			return getTextContent(data, content);
		} else if (itemDb.isLoading) {
			return "Loading....";
		} else if (content?.id === "parent") {
			// return itemDb[content.field]?.toString() || ",,";
			return getTextContent(itemDb, content);
		}
		return "Error occured";
	}
	return "Not available";
};

export const parseImageSrc = (db, content) => {
	if ("params" in content) {
		return parseImgURL(content);
	}
	const itemDb = db[content?.id];
	if (itemDb) {
		if (itemDb.isSuccess) {
			const img_id = itemDb.data[content.field];
			if (img_id) {
				if (typeof img_id === "object") {
					//full image object hai
					return parseImgURL(img_id);
				}
				return getImageURL(img_id);
			}
			return "/img_pp.png";
		} else if (itemDb.isLoading) {
			return "/img_pp.png"; // Loading placeholder image
		} else if (content?.id === "parent") {
			const img_id = itemDb[content.field];
			if (img_id) {
				return getImageURL(img_id);
			}
			return "/img_pp.png";
		}
		return "/img_pp.png"; // Error placeholder image
	}
	return "Not available";
};

export const parseImageArray = (db, content) => {
	if (Array.isArray(content)) {
		return content.length > 0 ? content : [{ full: "/img_pp.png" }];
	}
	const itemDb = db[content?.id];
	if (itemDb && itemDb.isSuccess) {
		const value = itemDb.data[content.field];
		if (!value?.length > 0) {
			return [];
		}
		return value.map((v) => {
			return {
				full: getImageURL(v.id),
			};
		});
	}
	return [];
};
