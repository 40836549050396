import log from "cslog";
import {
	bookInfoState,
	bookLayoutState,
	currentContextState,
	customDomainState,
	titleState,
	urlSettingsState,
	usedSchemasState
} from "../db/bookConfig";
import { globalVariablesConfigState, globalVariablesState } from "../db/dataDb";
import {
	globalConfigState,
	// gstyleArrayState,
	themeArrayState,
	themeColorState,
	themeFontSetState,
	themePageState,
} from "../db/gstyleDb";
import { permissionsPageState, protectedAccessState, rolesState } from "../db/permissions";
import { seoBasicState } from "../db/seoDb";
import {
	extensionSettingsState,
	installedExtensionsState,
} from "../db/storeDb";
import { viewerState } from "../db/viewerDb";
import { encodeMagazineData } from "./magazine/encode";
import { encodeWebsiteData, encodeWebsiteDataMinimal } from "./website/encode";
import produce from "immer";

export const encodeData = async (snapshot, config, minimal = false) => {
	log.h4("Encoding Website Data");

	const out = {};

	// out["organization"] = await snapshot.getPromise(organizationState);
	const current_context = await snapshot.getPromise(currentContextState);
	out["organization"] = current_context?.organization;
	// out["person"] = current_context?.person;

	out["title"] = await snapshot.getPromise(titleState);
	// out["slug"] = "demo_slug";

	out["custom_domain"] = await snapshot.getPromise(customDomainState);

	const info = await snapshot.getPromise(bookInfoState);
	out["info"] = info;

	out["layout"] = await snapshot.getPromise(bookLayoutState);

	let gstyle = await snapshot.getPromise(themeArrayState);
	log.d(gstyle, "gstyle before deleting");

	if ("gstyles" in gstyle?.gstyles) {
		log.p("now deleting gstyle");
		gstyle = produce(gstyle, (draft) => {
			delete draft.gstyles?.gstyles;
		})
	}

	log.d(gstyle, "Final gstyle to save")

	out["gconfig"] = {
		gconfig: {
			colors: await snapshot.getPromise(themeColorState),
			fontSets: await snapshot.getPromise(themeFontSetState),
			page: await snapshot.getPromise(themePageState),
		},
		gstyles: gstyle,
		globalConfig: await snapshot.getPromise(globalConfigState),
	};

	if (info.layout === "responsive" || info.layout === "grid" || info.layout === "document") {
		if (minimal) {
			out["data"] = await encodeWebsiteDataMinimal(snapshot, null);
		} else {
			// out["data"] = await encodeWebsiteData(snapshot, null);
			const temp_data = await encodeWebsiteData(snapshot, null);
			out["inputs"] = temp_data["inputs"]
			delete temp_data["inputs"];
			out["data"] = temp_data;
		}
	} else if (info.layout === "absolute") {
		// out["data"] = await encodeMagazineData(snapshot, null);
		const temp_data = await encodeMagazineData(snapshot, null);
		out["inputs"] = temp_data["inputs"]
		delete temp_data["inputs"];
		out["data"] = temp_data;
	}

	out["others"] = {
		globalVariables: await snapshot.getPromise(globalVariablesState),
		globalVariablesConfig: await snapshot.getPromise(
			globalVariablesConfigState
		),
		extensionSettings: await snapshot.getPromise(extensionSettingsState),
	};


	const out_schemas = [];
	const temp_schemas = await snapshot.getPromise(usedSchemasState);
	Object.keys(temp_schemas).forEach((key) => {
		out_schemas.push({
			qdb_creation_schema_id: key
		})
	})
	log.d(out_schemas, "OUT schemas")
	out["used_schemas"] = out_schemas;

	out["seo"] = await snapshot.getPromise(seoBasicState);

	out["viewers"] = await snapshot.getPromise(viewerState);

	out["extensions"] = await snapshot.getPromise(installedExtensionsState);

	out["urlsettings"] = await snapshot.getPromise(urlSettingsState);

	out["permissions"] = {
		enable: false,
		roles: await snapshot.getPromise(rolesState),
		page_permissions: await snapshot.getPromise(permissionsPageState),
		protected_access: await snapshot.getPromise(protectedAccessState)
	};

	// out["inputs"] = {};

	log.d(out, "Encoded DATA");
	return out;
};
