const PRIMARY_COMMON = {
	header: {
		css: null,
		fixStyle: {
			fontWeight: 'bold',
			boxSizing: 'border-box'
		},
		style: {
			color: {
				type: 'config',
				value: 'neutralColor'
			},
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			// fontFamily: `Arial, Helvetica, sans-serif`,
			fontFamily: {
				type: 'config',
				value: 'primaryFont'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			textShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			// fontSize: 30,
			// letterSpacing: "normal",
			transition: '0.5s',
			lineHeight: 1.3,
			filter: {
				type: 'none',
				params: {},
				value: 'none'
			}
		},
		mobile: {},
		input: {
			headingTag: {
				type: 'h4',
				params: 20,
				value: '20px'
			},
			content: 'Type Heading Here',
			uppercase: false,
			bold: true,
			textAlign: 'left',
			letterSpacing: {
				type: 'auto',
				value: 'normal'
			}
		},
		dataFields: {
			content: 'text'
		},
		ai: {
			label: '',
			description: '',
			is_derived: false
		}
		// classes: {
		//     q_hide_on_mobile: false,
		// },
	},
	p: {
		css: null,
		style: {
			color: '#4A4A4A',
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			fontSize: 16,
			lineHeight: 1.6,
			padding: {
				type: 'single',
				params: 10,
				value: '10px'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxSizing: 'border-box',
			// fontFamily: `Arial, Helvetica, sans-serif`,
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			}
			// letterSpacing: "normal",
		},
		mobile: {},
		input: {
			formatting: true,
			content: 'Start Editing...',
			fontSize: 16,
			lineHeight: 1.6,
			columnCount: 1,
			textAlign: 'left',
			maxLines: {
				type: 'auto',
				value: 'auto'
			}
		},
		dataFields: {
			content: 'text'
		},
	},
	text: {
		css: null,
		style: {
			color: '#4A4A4A',
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxSizing: 'border-box',
			// fontFamily: `Arial, Helvetica, sans-serif`,
			fontFamily: {
				type: 'config',
				value: 'primaryFont'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			}
			// letterSpacing: "normal",
		},
		mobile: {},
		input: {
			formatting: true,
			content: 'Start Editingg...',
			fontSize: 24,
			lineHeight: 1.2,
			// columnCount: 1,
			textAlign: 'left'
		},
		dataFields: {
			content: 'text'
		},
	},
	button: {
		css: null,
		fixStyle: {
			outline: 'none',
			boxSizing: 'border-box',
			textShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
			backgroundSize: 'contain'
			// backgroundRepeat: "no-repeat",
		},
		style: {
			color: 'white',
			background: {
				type: 'config',
				value: 'secondryColor'
			},
			fontFamily: {
				type: 'config',
				value: 'primaryFont'
			},
			borderStyle: 'none',
			borderColor: 'black',
			borderWidth: {
				type: 'single',
				params: 5,
				value: 5
			},
			padding: {
				type: 'single',
				params: 9,
				value: '9px'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			transition: '0.3s'
		},
		style__hover: {
			background: {
				type: 'simple',
				params: 'black',
				value: 'black'
			}
		},

		hover: {
			cursor: 'pointer',
			background: {
				//simple, gradient, image
				type: 'simple',
				params: '#3354bf',
				value: '#3354bf'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			}
		},
		states: ['normal', 'hover', 'selected'],
		mobile: {},
		input: {
			buttonType: {
				type: 'text',
				params: {
					text: 'Click Me'
				}
			},
			size: 16,
			bold: true,
			action: {
				type: 'none'
			},
			hoverAnimation: 'qureal-grow'
		},
		dataFields: {
			buttonType: "",
			action: ''
		},
	},
	link: {
		css: null,
		style: {
			color: 'royalblue',
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			fontSize: 14,
			lineHeight: 1.4,
			padding: {
				type: 'single',
				params: 7,
				value: '7px'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxSizing: 'border-box',
			// fontFamily: `Arial, Helvetica, sans-serif`,
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			}
			// letterSpacing: "normal",
		},
		mobile: {},
		input: {
			linkType: {
				type: 'text',
				params: {
					text: 'Click Me'
				}
			},
			bold: true,
			content: '<b>Link Text</b>',
			action: {
				type: 'page',
				params: 1,
				value: 1
			}
		},
		dataFields: {
			content: 'text'
		}
	},
	richtext: {
		css: null,
		style: {
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			fontSize: 18,
			lineHeight: 1.6,
			padding: {
				type: 'single',
				params: 10,
				value: '10px'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxSizing: 'border-box'
		},
		mobile: {},
		input: {
			html: '',
			columnCount: 1
		},
		dataFields: {
			html: ""
		},
	},
	htmltext: {
		css: null,
		style: {
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			padding: {
				type: 'single',
				params: 10,
				value: '10px'
			},
			borderRadius: {
				type: 'single',
				params: 0,
				value: 0
			},
			boxSizing: 'border-box',
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			},
			fontFamilyHeadings: {
				type: 'config',
				value: 'primaryFont'
			},
		},
		mobile: {},
		input: {
			html: [
				{
					type: 'paragraph',
					children: [
						{
							text: ''
						}
					]
				}
			],
			fontSize: 16,
			lineHeight: 1.4,
			letterSpacing: {
				type: 'auto',
				value: 'normal'
			},
			columnCount: 1
		},
		dataFields: {
			html: ""
		},
	},
	divider: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			margin: {
				type: 'single',
				params: 10,
				value: '10px'
			},
			color: '#9B9B9B',
			transition: '0.3s'
		},
		mobile: {},
		input: {
			thickness: 2,
			lineStyle: 'solid'
		},
		itype: {
			thickness: 'standardRange',
			lineStyle: 'selectOption'
		}
	},
	richDialog: {
		css: null,
		fixStyle: {
			outline: 'none',
			boxSizing: 'border-box',
			textShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
			backgroundSize: 'contain',
			// backgroundRepeat: "no-repeat",
			margin: {
				type: 'single',
				params: 0,
				value: '0px'
			}
		},
		style: {
			color: 'white',
			background: {
				type: 'simple',
				params: '#446CF6',
				value: '#446CF6'
			},
			fontSize: 14,
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			},
			border: {
				type: 'none',
				params: {
					width: 1,
					color: 'black'
				},
				value: 'none'
			},
			padding: {
				type: 'single',
				params: 9,
				value: '9px'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			transition: '0.3s'
		},
		hover: {
			cursor: 'pointer',
			background: {
				//simple, gradient, image
				type: 'simple',
				params: '#3354bf',
				value: '#3354bf'
			}
		},
		mobile: {},
		input: {
			html: [],
			buttonType: {
				type: 'text',
				params: {
					text: 'Click Me'
				}
			},
			size: 16,
			hoverAnimation: 'qureal-grow'
		}
	},
	img: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			transition: '0.3s',
			borderRadius: {
				type: 'single',
				params: 0,
				value: 0
			},
			// border: {
			//     type: "none",
			//     params: {
			//         width: 1,
			//         color: "black",
			//     },
			//     value: "none",
			// },
			borderStyle: 'none',
			borderColor: 'black',
			borderWidth: {
				type: 'single',
				params: 5,
				value: 5
			},
			opacity: 1,
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			filter: {
				type: 'none',
				params: {},
				value: 'none'
			}
		},
		hover: {},
		mobile: {},
		input: {
			imageSource: {
				params: null,
				// value: process.env.PUBLIC_URL + "img_pp.png",
				value: '/img_pp.png'
			},
			hoverAnimation: 'qureal-none',
			action: {
				type: 'none'
			},
			mask: null,
			flipHorizontal: false,
			flipVertical: false,
		},
		dataFields: {
			imageSource: ""
		},
	},
	video: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			transition: '0.3s',
			borderRadius: {
				type: 'single',
				params: 0,
				value: 0
			},
			borderStyle: 'none',
			borderColor: 'black',
			borderWidth: {
				type: 'single',
				params: 5,
				value: 5
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			}
		},
		hover: {},
		mobile: {},
		input: {
			youtubeVideoURL: {
				params: 'www.youtube.com/embed/rUWxSEwctFU',
				value: 'rUWxSEwctFU'
			},

			autoplay: false,
			playInLoop: false,
			showControls: true
		},
		dataFields: {
			youtubeVideoURL: ""
		},
	},
	icon: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			color: 'black',
			background: {
				type: 'simple',
				params: '#E0E1E2',
				value: '#E0E1E2'
			},
			textShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},

			transition: '0.3s'
		},
		hover: {},
		mobile: {},
		input: {
			iconSource: {
				params: {
					type: 'fa',
					name: 'coffee'
				},
				value: 'fa fa-coffee'
			},
			iconSize: {
				type: 'small',
				params: 30,
				value: '30px'
			},
			frameType: 'solid',
			frameShape: { type: 'circle', value: 'circle' },
			hoverAnimation: 'qureal-none'
		},
		dataFields: {
			iconSource: ""
		},
	},
	graphic: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			transition: '0.3s',
			borderRadius: {
				type: 'single',
				params: 0,
				value: 0
			},
			// border: {
			//     type: "none",
			//     params: {
			//         width: 1,
			//         color: "black",
			//     },
			//     value: "none",
			// },
			borderStyle: 'none',
			borderColor: 'black',
			borderWidth: {
				type: 'single',
				params: 5,
				value: 5
			},
			opacity: 1,
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			filter: {
				type: 'none',
				params: {},
				value: 'none'
			}
		},
		hover: {},
		mobile: {},
		input: {
			svgSource: {
				params: {
					srcType: "lib",
					id: "605526b6-9abb-4e5d-9053-445e29c8581c",
					title: "free hand shape",
					filename_disk: "605526b6-9abb-4e5d-9053-445e29c8581c.svg",
					filename_download: "free hand shape.svg",
					storage: "local",
					type: "image/svg+xml",
					filesize: 1089,
					full: "https://api.qureal.com/assets/605526b6-9abb-4e5d-9053-445e29c8581c.svg"
				},
				value: "https://api.qureal.com/assets/605526b6-9abb-4e5d-9053-445e29c8581c.svg",
				og_colors: [
					"#e7b2a3"
				],
				colors: [
					"#e7b2a3"
				]
			},
			// lightbox: true,
			flipHorizontal: false,
			flipVertical: false,
			hoverAnimation: 'qureal-none',
			action: {
				type: 'none'
			}
		},
		dataFields: {
			svgSource: ""
		},
	},
	shape: {
		css: null,
		fixStyle: {
			boxSizing: 'border-box',
			maxWidth: '100%'
		},
		style: {
			// color: "black",
			// background: {
			// 	type: 'simple',
			// 	params: '#4AE27D',
			// 	value: '#4AE27D'
			// },
			border: {
				type: 'none',
				params: {
					width: 1,
					color: 'black'
				},
				value: 'none'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			// margin: {
			//     type: "single",
			//     params: 0,
			//     value: "0px",
			// },
			// padding: {
			//     type: "single",
			//     params: 10,
			//     value: "10px",
			// },
			transition: '0.3s'
		},
		hover: {},
		mobile: {},
		input: {
			fill: {
				type: 'simple',
				param: '#D5E5EF',
				value: '#D5E5EF'
			},
			opacity: 1
			// hoverAnimation: "qureal-none",
		},
		itype: {
			// iconSource: "iconSrc",
			// iconSize: "iconSize",
			// iconGap: "iconGap",
			// frameType: "optionRowIcon",
			frameShape: 'frameShape',
			opacity: 'opacity',
			// alignImage: "optionRowIcon",
			hoverAnimation: 'selectOption'
		},
		dataFields: {
			shapeData: ''
		}
	},
	slidingText: {
		css: null,
		fixStyle: {},
		style: {
			color: 'green',
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			// fontFamily: `Arial, Helvetica, sans-serif`,
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			},
			borderRadius: {
				type: 'single',
				params: 3,
				value: 3
			},
			border: {
				type: 'none',
				params: {
					width: 1,
					color: 'black'
				},
				value: 'none'
			},
			boxShadow: {
				type: 'none',
				params: {},
				value: 'none'
			},
			// fontSize: 30,
			// letterSpacing: "normal",
			transition: '0.5s'
		},
		mobile: {},
		input: {
			content: 'Heading',
			fontSize: 18,
			speed: 25,
			uppercase: false,
			bold: true,
			directionReverse: false,
			pauseOnHover: true,
			pauseOnClick: false
			// direction: "left",
		},
		dataFields: {
			content: ''
		}
	},
	list: {
		css: null,
		style: {
			margin: {
				type: 'single',
				params: 0,
				value: '0px'
			},
			padding: {
				type: 'single',
				params: 10,
				value: '10px'
			},
			color: 'black',
			background: {
				type: 'simple',
				params: 'transparent',
				value: 'transparent'
			},
			fontSize: 18,
			lineHeight: 1.3,
			fontFamily: {
				type: 'config',
				value: 'secondryFont'
			}
		},
		hover: {},
		mobile: {},
		input: {
			textList: ['Item 1', 'Item 2', 'Item 3'],
			listStyle: {
				tag: 'ul',
				style: 'disc'
			},
			itemGap: 1
		},
		dataFields: {
			textList: ''
		}
	}
}

export default PRIMARY_COMMON
