import PRIMARY_COMMON from '../common/primary'
import { combineElements } from '../../../parser/allparser'

const ABSOLUTE = {
	header: {
		input: {
			autofitText: {
				enable: false,
				params: {
					min: 1,
					max: 120
				}
			}
		},
		style: {
			padding: {
				type: 'single',
				params: 5,
				value: '5px'
			}
		},
		ai: {
			aiLabel: '',
			is_derived: false,
			aiDescription: ''
		}
	},
	p: {
		style: {
			padding: {
				type: 'single',
				params: 0,
				value: '0px'
			}
		},
		input: {
			alignChilds: 'flex-start'
		}
	},
	text: {
		input: {
			autofit: "none", //none= fix text, fix box, box= fix text, resize box, text= fix box, resize text
		}
	},
	button: {},
	link: {},
	richtext: {},
	htmltext: {
		style: {
			padding: {
				type: 'single',
				params: 0,
				value: '0px'
			}
		},
		input: {
			autofit: "none", //none= fix text, fix box, box= fix text, resize box, text= fix box, resize text
		}
	},
	richDialog: {},
	img: {
		input: {
			imageFit: "cover",
			imagePosition: "center center",
		}
	},
	divider: {},
	list: {
		style: {
			padding: {
				type: 'single',
				params: 0,
				value: '0px'
			}
		},
	},
	video: {},
	icon: {},
	shape: {},
	slidingText: {},
	graphic: {}
}

const PRIMARY_ABSOLUTE = combineElements(PRIMARY_COMMON, ABSOLUTE)
export default PRIMARY_ABSOLUTE
