import React, { Suspense } from "react";
// import Blank from "./elements_freeview_static/Blank";
import BookPage from "./elements_freeview_static/book/BookPage";
import Button from "./elements_freeview_static/Button";
// import Button3D from "./elements_freeview_static/custom/Button3D";
// import CodeView from "./elements_freeview_static/custom/CodeView";
// import Countdown from "./elements_freeview_static/custom/Countdown";
import Divider from "./elements_freeview_static/Divider";
import Header from "./elements_freeview_static/Header";
// import IconList from "./elements_freeview_static/IconList";
import Img from "./elements_freeview_static/Img";
import List from "./elements_freeview_static/List";
// import NavMenu from "./elements_freeview_static/NavMenu";
import PageLink from "./elements_freeview_static/PageLink";
import QIcon from "./elements_freeview_static/QIcon";
import RichText from "./elements_freeview_static/RichText";
import Shape from "./elements_freeview_static/Shape";
import Text from "./elements_freeview_static/Text";
import Video from "./elements_freeview_static/Video";
import AudioPlayer from "./elements_freeview_static/custom/AudioPlayer";
import QRCodeView from "./elements_freeview_static/custom/QRCodeView";
import SlidingText from "./elements_freeview_static/SlidingText";
import RichDialog from "./elements_freeview_static/RichDialog";
import HtmlText from "./elements_freeview_static/HtmlText";
import IconList from "./elements_freeview_static/IconList";
import InvisibleButton from "./elements_freeview_static/InvisibleButton";
import Graphic from "./elements_freeview_static/Graphic";
import NewText from "./elements_freeview_static/NewText";
import SocialIcons from "./elements_freeview_static/SocialIcons"

// import Viewer3D from "./elements_freeview_static/custom/Viewer3D";
const Viewer3D = React.lazy(() =>
	import("./elements_freeview_static/custom/Viewer3D")
);

function Element({
	id,
	db,
	index,
	setHoverIndex,
	direction,
	device,
	gs,
	preview,
	dydb,
	ctx,
}) {
	// const data = useRecoilValue(elementState(id));
	const data = db[id];

	if (!data) {
		return <p>Nothing found</p>;
	}

	switch (data.type) {
		case "bookPage":
			return (
				<BookPage
					id={id}
					db={db}
					index={index}
					device={device}
					gs={gs}
					preview={preview}
				/>
			);
		case "header":
			return (
				<Header
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					device={device}
					gs={gs}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "p":
			return (
				<Text
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "text":
			return (
				<NewText
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "link":
			return (
				<PageLink
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "richtext":
			return (
				<RichText
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "htmltext":
			return (
				<HtmlText
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "button":
			return (
				<Button
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "invisibleButton":
			return (
				<InvisibleButton
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "richDialog":
			return (
				<RichDialog
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "img":
			return (
				<Img
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "graphic":
			return (
				<Graphic
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		case "video":
			return (
				<Video
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "icon":
			return (
				<QIcon
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "shape":
			return (
				<Shape
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "viewer3d":
			return (
				<Suspense fallback={<h3>Loading 3D Viewer</h3>}>
					<Viewer3D
						id={id}
						db={db}
						index={index}
						setHoverIndex={setHoverIndex}
						direction={direction}
						gs={gs}
						device={device}
					/>
				</Suspense>
			);
		case "socialIcons":
			return <SocialIcons
				id={id}
				db={db}
				index={index}
				setHoverIndex={setHoverIndex}
				direction={direction}
				gs={gs}
				device={device}
				dydb={dydb}
				ctx={ctx}
			/>
		case "audioPlayer":
			return (
				<AudioPlayer
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "qrCode":
			return (
				<QRCodeView
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		// case "button3d":
		//     return (
		//         <Button3D
		//             id={id}
		//             db={db}
		//             index={index}
		//             setHoverIndex={setHoverIndex}
		//             direction={direction}
		//             gs={gs}
		//             device={device}
		//         />
		//     );
		case "list":
			return (
				<List
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "iconList":
			return (
				<IconList
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
					dydb={dydb}
					ctx={ctx}
				/>
			);
		// case "navMenu":
		//     return (
		//         <NavMenu
		//             id={id}
		//             db={db}
		//             index={index}
		//             setHoverIndex={setHoverIndex}
		//             direction={direction}
		//             gs={gs}
		//             device={device}
		//         />
		//     );
		// case "blank":
		//     return (
		//         <Blank
		//             id={id}
		//             db={db}
		//             index={index}
		//             setHoverIndex={setHoverIndex}
		//             direction={direction}
		//             gs={gs}
		//             device={device}
		//         />
		//     );
		case "divider":
			return (
				<Divider
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		case "slidingText":
			return (
				<SlidingText
					id={id}
					db={db}
					index={index}
					setHoverIndex={setHoverIndex}
					direction={direction}
					gs={gs}
					device={device}
				/>
			);
		// case "countdown":
		//     return (
		//         <Countdown
		//             id={id}
		//             db={db}
		//             index={index}
		//             setHoverIndex={setHoverIndex}
		//             direction={direction}
		//             gs={gs}
		//             device={device}
		//         />
		//     );
		// case "codeview":
		//     return (
		//         <CodeView
		//             id={id}
		//             db={db}
		//             index={index}
		//             setHoverIndex={setHoverIndex}
		//             direction={direction}
		//             gs={gs}
		//             device={device}
		//         />
		//     );
		default:
			return <p>Element Not found: {data.type}</p>;
	}
}

export default Element;
