import log from "cslog"
import { startCase } from "lodash";

const TYPES_MAPPING = {
	"string": "text"
}


function parsePropertyOne(property_id, property_value, example, key_prefix = "") {
	if (property_value?.itype === "img") {
		return {
			name: `${key_prefix ? `${key_prefix}_` : ""}${property_id}`,
			display_name: `${key_prefix ? `${startCase(key_prefix)} ` : ""}${startCase(property_id)}`,
			type: "img",
			default_value: example?.[property_id] || property_value?.description || "Default value",
		}
	}
	return {
		// name: property_id,
		name: `${key_prefix ? `${key_prefix}_` : ""}${property_id}`,
		// display_name: startCase(property_id),
		display_name: `${key_prefix ? `${startCase(key_prefix)} ` : ""}${startCase(property_id)}`,
		type: TYPES_MAPPING[property_value?.type || "string"] || "text",
		default_value: example?.[property_id] || property_value?.description || "Default value",
	}
}

function parseProperties(properties, example, key_prefix = "") {
	const fields = [];
	for (const [key, value] of Object.entries(properties)) {
		if (value.type === "string") {
			fields.push(parsePropertyOne(key, value, example, key_prefix));
		} else if (value.type === "array") {
			log.d(value, "Property value Array")
			const inner_properties = value.items?.properties;
			if (inner_properties) {
				const array_length = value.length || 1;
				for (let i = 0; i < array_length; i++) {
					fields.push(...parseProperties(inner_properties, example[key]?.[i], `${key}_${i + 1}`));
				}

				// fields.push(...parseProperties(inner_properties, example[key], key));
			}
		}
	}
	return fields
}


function prepareExtra(schema, example) {
	const out = {};
	const properties = schema?.properties;
	for (const [key, value] of Object.entries(properties)) {
		if (value.type === "string") {
			out[key] = {
				maxChar: value?.maxChar || example?.[key]?.length || 20
			}
		} else if (value.type === "array") {
			out[key] = prepareExtra(value.items, example[key]?.[0])
		}
	}
	return out
}


export function presetToDataBlocks(preset) {
	log.p("Converting preset to data blocks");
	log.d(preset, "Preset")

	const ts = preset.true_schema;
	const examples = preset.examples;

	const example_one = examples?.[0];

	const fields = [];  // name, display_name, type, default_value  

	const properties = ts?.schema?.properties;
	if (properties) {
		fields.push(...parseProperties(properties, examples[0]))
	}

	log.d(fields, "Preset Fields out")


	return {
		fields: fields,
		qureal_schema_id: preset.id,
		extra: prepareExtra(ts?.["schema"], example_one),
		// extra: {},
		default: example_one
	};
}



export function encodePresetValues(values, prefix = "") {
	log.d(values, "Values in encodePresetValues")
	// log.d(fields, "Fields in encodePresetValues")
	let out = {};
	for (const [key, value] of Object.entries(values)) {
		// if (typeof value === "string" || (typeof value === "object" && !Array.isArray(value))) {
		// 	out[`${prefix}${key}`] = value;
		// } else if (Array.isArray(value)) {
		// 	for (let i = 0; i < value.length; i++) {
		// 		const prefix = `${key}_${i + 1}_`;
		// 		out = { ...out, ...encodePresetValues(value[i], prefix) };
		// 	}
		// }

		if (Array.isArray(value)) {
			for (let i = 0; i < value.length; i++) {
				const prefix = `${key}_${i + 1}_`;
				out = { ...out, ...encodePresetValues(value[i], prefix) };
			}
		} else if (typeof value === "string" || typeof value === "object") {
			out[`${prefix}${key}`] = value;
		}
	}
	log.d(out, "out in encodePresetValues")
	return out;
}